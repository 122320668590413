<template>
    <div data-name="defaultLayout"
        class="defaultLayout flex flex-col w-screen h-full xl:h-full xl:w-full xl:rounded-none xl:max-w-none max-w-[1252px] rounded-xl shadow-lg bg-containerbg relative">
        <div class="z-30 sticky top-0">
            <componentHeader></componentHeader>
            <navBar></navBar>
        </div>

            <slot></slot>

        <componentFooter></componentFooter>
    </div>
</template>

<script setup lang="ts">

</script>

<style></style>